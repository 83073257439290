const dictType = [
    {
        id: "1",
        label: "上交所",
        value: '1'
    },
    {
        id: "2",
        label: "深交所",
        value: '2'
    },
    {
        id: "3",
        label: "港交所",
        value: '3'
    }
];
export { dictType };