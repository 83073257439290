<template>
    <div class="gupiao-a-pe">
        <el-form
            ref="query-form"
            :inline="true"
            :model="form"
            class="form-inline"
            label-width="80px"
            size="mini"
            label-position="left"
        >
            <el-row>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="form.type" placeholder="类型" clearable>
                        <el-option
                            v-for="dictItem in dict.type"
                            :key="dictItem.id"
                            :value="dictItem.value"
                            :label="dictItem.label"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="start_calc_time">
                    <el-date-picker
                        v-model="form.start_calc_time"
                        placeholder="开始时间"
                        value-format="yyyyMMdd"
                        clearable
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="end_calc_time">
                    <el-date-picker
                        v-model="form.end_calc_time"
                        placeholder="结束时间"
                        value-format="yyyyMMdd"
                        clearable
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryTable"
                        >查询</el-button
                    >
                    <el-button
                        @click="resetForm"
                        icon="el-icon-refresh-right"
                    ></el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <TableList
            class="pe-table"
            ref="peTable"
            :query="form"
            :columns="table.columms"
            :tableData.sync="table.tableData"
            :page.sync="table.page"
            :doHandler="table.doHandler"
            fullHeight
            border
            hasIndex
            indexLabel="序号"
        >
            <template slot="type" slot-scope="{ value }">
                {{ value | typeFilter }}
            </template>
            <template slot="calc_time" slot-scope="{ value }">
                {{ moment(value, "YYYYMMDD").format("YYYY-MM-DD") }}
            </template>
        </TableList>
    </div>
</template>

<script>
import { getPeListApi } from "@/api/GuPiao/A/pe.js";
import TableList from "@/components/table-list";
import columms from "./columns";
import moment from "moment";
import { dictType } from "./dict";
export default {
    name: "GuPiaoAPe",
    components: {
        TableList
    },
    data() {
        return {
            moment,
            dict: {
                type: dictType
            },
            form: {
                type: "2",
                start_calc_time: null,
                end_calc_time: null,
                sort_desc: "calc_time"
            },
            table: {
                columms,
                page: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                doHandler: getPeListApi,
                tableData: []
            }
        };
    },
    methods: {
        resetForm() {
            this.$refs["query-form"].resetFields();
        },
        queryTable() {
            this.$refs["peTable"].doQuery();
        }
    },
    filters: {
        typeFilter(value) {
            for (const dictItem of dictType) {
                if (value == dictItem.value) {
                    return dictItem.label;
                }
            }
            return "未知";
        }
    }
};
</script>

<style lang="scss" scoped>
.gupiao-a-pe {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    .pe-table {
        flex: 1;
    }
}
</style>
