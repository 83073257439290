export default [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "type",
        label: "类型",
        minWidth: 120
    },
    {
        prop: "calc_time",
        label: "日期",
        minWidth: 140
    },
    {
        prop: "pe",
        label: "市盈率",
        minWidth: 90
    }
];
